import React, { useCallback, useState } from 'react'
import './index.scss'
import SelectTopUpAmount from './SelectTopUpAmount'
import InputTopUpAmount from './InputTopUpAmount'
import NextButton from '../../NextButton'

import { navigate } from 'gatsby'

const TopUpBalance = () => {
    let [balance, setBalance] = useState(0)

    let clearAmount = useCallback(() => {
        setBalance(0)
    }, [])

    let onInputChange = useCallback((e) => {
        setBalance(e.target.value)
    }, [])

    return <> <div className='topup-balance'>
        <SelectTopUpAmount onSelect={(value) => {
            setBalance(value)
        }} />
        <InputTopUpAmount amount={balance} onClearAmount={clearAmount} onInputChange = {onInputChange} />
    </div>
        <NextButton onClick={() => {
            navigate('/user/topupcheckout', {
                state: {
                    amount: balance
                }
            })
        }} />
    </>
}

export default TopUpBalance