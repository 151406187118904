import React from 'react'
import './index.scss'

const InputTopUpAmount = ({amount = 0, onClearAmount = () => {}, onInputChange = () => {}}) => {
    return <div className='input-topup-amount'>
        <div className='input-topup-amount__label'>Input Amount</div>
        <div className='input-topup-amount__currency'>PHP</div>
        <input type="number" onChange={onInputChange} className='input-topup-amount__input' value={amount} />
        <div className='input-topup-amount__clear' onClick={onClearAmount}>X</div>
    </div>
}

export default InputTopUpAmount