import React, { useState } from 'react'
import './index.scss'

import TopUpAmount from './TopUpAmount'

const SelectTopUpAmount = ({ onSelect = () => { console.log('selected topup amount') } }) => {
    let [selectedBalance, selectBalance] = useState(0)

    const onClick = (balance = 0) => {
        onSelect(balance)
        selectBalance(balance)
    }

    return <div className='select-topup-amount'>
        <div className='select-topup-amount__text'>Select topup amount</div>
        <div className='select-topup-amount__row'>
            <TopUpAmount value={100} selected={selectedBalance === 100} onSelect={onClick}/>
            <TopUpAmount value={200} selected={selectedBalance === 200} onSelect={onClick} />
            <TopUpAmount value={300} selected={selectedBalance === 300} onSelect={onClick} />
        </div>
        <div className='select-topup-amount__row'>

            <TopUpAmount value={400} selected={selectedBalance === 400} onSelect={onClick} />
            <TopUpAmount value={500} selected={selectedBalance === 500} onSelect={onClick} />
            <TopUpAmount value={600} selected={selectedBalance === 600} onSelect={onClick} />
        </div>
    </div>
}

export default SelectTopUpAmount