import React from 'react'
import Page from '../../components/SparkleWallet/UserTopUp/InputAmountForm'
import generatePagePropsOnBuildTime from '../../helpers/generatePagePropsOnBuildTime'
import PageGradient from '../../components/PageGradient'

const UserTopup = (props) => {
    return <PageGradient><Page {...generatePagePropsOnBuildTime(props, {
        amount: 0
    })} />
    </PageGradient>
}

export default UserTopup