export default function(props= {}, state = {}){
   return typeof window === 'undefined' ? 
    {
        ...props, 
        location: {
            state: {
                ...state
            }
        }
    } : props
}