import React from 'react'
import './index.scss'

const AvailableBalance = ({balance = '0.00'}) => {
    return <div
        className='available-balance'>
        <div className='available-balance__text'>
            Available Balance:
        </div>
        <div className='available-balance__amount'>
           PHP {balance}
        </div>
    </div>
}

export default AvailableBalance