import React from 'react'

import './index.scss'

import Header from '../../components/Header'
import SparkleLogo from '../../components/SparkleLogo'
import AvailableBalance from './AvailableBalance'
import TopUpBalance from './TopUpBalance/view'
import NextButton from './NextButton'

const UserTopUp = () => {
    return <div className='user-topup'>
        <Header />
        <SparkleLogo />
        <AvailableBalance />
        <TopUpBalance />
    </div>
}

export default UserTopUp